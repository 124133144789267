import InputWrapper from '@components/editor/module/inputs/InputWrapper'
import BlockHeader from '@components/editor/scene/blocks/BlockHeader'
import BlockGroup from '@components/editor/scene/blocks/BlockGroup'

export default {

  components: {
    InputWrapper,
    BlockHeader,
    BlockGroup
  },

  props: {
    sequence: {
      required: true,
      type: Object
    }
  },

  methods: {

    getSetting (key) {
      return '0'
      // return this.gui.getSetting(key)
    },

    onChange (key, value) {
      // this.gui.setSetting(key, value)
      // this.$emit('settings-changed')
    }

  }

}
