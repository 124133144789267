<template>
    <div class="bg-gray-800">
      <div class="flex flex-wrap">
        <div class="w-full">
          <BlockGroup
            :label="'Timing'"
            :closed="false"
          >
            <InputWrapper
              type="number"
              :label="'Sequence Duration (seconds)'"
              :value="duration"
              @change="duration = $event"
            />
          </BlockGroup>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { isNumber } from 'util'
import mixin from '../SequenceSettingMixin'
  
  export default {
    name: 'TimlineBlock',
  
    mixins: [mixin],

    computed: {
      timeline () {
        return this.sequence.timeline
      },

      duration: {
        get() {
          return this.timeline.duration
        },
        set(val) {
          if(val >= 1) this.timeline.duration = val 
        }
      },


        unitsOptions () {
            return {
                'seconds': 'Seconds',
                'frames': 'Frames'
            }
        },

        fpsOptions () {
            return {
                '15' : 15, 
                '30': 30,
                '60': 60
            }
        }
    }
  
  }
  </script>
  